//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    selMember: () => import("@/components/Selectors/MemberSelectCopy"),
    SpecialSchedulingSet: () => import("./specialSchedulingSet"),
  },
  props: {
    //时薪选项
    wageOptions: {
      type: Array,
      default: null,
    },
    //成员id
    generaId: {
      type: Number,
      default: null,
    },
    timeZonesOptions: {
      type: Array,
      default: null,
    },
    //团队
    teamValue: {
      type: Number,
      default: null,
    },
    //类型
    typePage: {
      type: String,
      default: null,
    },
  },
  data() {
    var absenteeism1Valid = (rule, value, callback) => {
      if (this.currencyForm.absenteeismMinuteCheck) {
        if (value) {
          if (
            this.currencyForm.seriousLateMinuteCheck &&
            value <= parseFloat(this.currencyForm.seriousLateMinute)
          ) {
            callback(new Error("时长必须大于严重迟到"));
          } else if (value <= 0) {
            callback(new Error("时长必须大于0"));
          }
        } else {
          if (!this.formData.seriousQuitMinute) {
            callback(new Error("两者必填一个"));
          }
        }
      }
      callback();
    };
    var absenteeism2Valid = (rule, value, callback) => {
      if (this.currencyForm.absenteeismMinuteCheck) {
        if (value) {
          if (
            this.currencyForm.seriousQuitMinuteCheck &&
            value <= parseFloat(this.currencyForm.seriousQuitMinute)
          ) {
            callback(new Error("时长必须大于严重早退"));
          } else if (value <= 0) {
            callback(new Error("时长必须大于0"));
          }
        } else {
          if (!this.currencyForm.seriousLateMinute) {
            callback(new Error("两者必填一个"));
          }
        }
      }
      callback();
    };
    return {
      loadingSpecial: false,
      specialData: [], //特殊排班日历的数据
      loading: false,
      saveBtnLoading: false,
      clearBtnLoading: false,
      screenMonth: new Date(),
      month: null,
      selSpecialDay: null, //选择的特殊日期
      selSpecialDayData: null, //选择的特殊日期的数据
      selMem: [], //选择的成员
      //时区选项
      currencyForm: {
        hourlyWage: null, //时薪
        hourlyWageOver: null, //加班时薪
        weekDay: [],
        timeZone: "China Standard Time", //时区
        startWork: null, //上班
        endWork: null, //下班
        LastCheckOutTime: null, //最晚下班时间
        IsMorrow: false,
        lateWork: null, //上班晚到
        lateWorkRadio: null, //上班晚到条件选择
        leaveEarly: null, //下班早走
        leaveEarlyRadio: null, //下班早走条件选择
        timeInterval: [
          {
            start: null,
            end: null,
          },
        ],
        seriousLateMinute: null, //严重迟到
        seriousLateMinuteCheck: false,
        seriousQuitMinute: null, //严重早退
        seriousQuitMinuteCheck: false,
        absenteeismLateMinute: null, //视为迟到
        absenteeismQuitMinute: null, //视为旷工
        absenteeismMinuteCheck: false,
      },
      Rules: {
        absenteeismLateMinute: [
          { validator: absenteeism1Valid, trigger: "blur" },
        ],
        absenteeismQuitMinute: [
          { validator: absenteeism2Valid, trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    screenMonth(val, oval) {
      if (val != oval) {
        this.month = this.screenMonth;
        this.getSpecialData();
      }
    },
    generaId(val, oval) {
      if (val != oval) {
        this.getPlanBCDetail();
        this.getSpecialData();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.month = this.screenMonth;
      this.getPlanBCDetail();
      this.getSpecialData();
    });
  },
  methods: {
    /**
     * 清除弹性打卡
     */
    cancleElastic() {
      this.currencyForm.lateWork = null; //上班晚到
      this.currencyForm.lateWorkRadio = null; //上班晚到条件选择
      this.currencyForm.leaveEarly = null; //下班早走
      this.currencyForm.leaveEarlyRadio = null; //下班早走条件选择
    },
    /**
     * 获取通用排班详情
     */
    getPlanBCDetail() {
      this.loading = true;
      this.$http
        .post("/Attendance/PlanBC/GetGeneralPlanBCDetail.ashx", {
          UserId: this.generaId,
          teamId: this.teamValue,
        })
        .then((resp) => {
          if (resp.res == 0) {
            const data = resp.data.Data;
            if (data) {
              this.currencyForm.weekDay = data.PlanWeekWorkDays;
              this.currencyForm.hourlyWage = data.WageTypeId;
              this.currencyForm.hourlyWageOver = data.WageTypeOverId;
              this.currencyForm.timeZone = data.TimeZone;
              this.currencyForm.startWork = data.CheckInTime;
              this.currencyForm.endWork = data.CheckOutTime;
              this.currencyForm.LastCheckOutTime = data.LastCheckOutTime;
              this.currencyForm.IsMorrow = data.IsMorrow;

              this.currencyForm.seriousLateMinute = data.SeriousLateMinute;
              this.currencyForm.seriousQuitMinute = data.SeriousQuitMinute;
              this.currencyForm.absenteeismLateMinute =
                data.AbsenteeismLateMinute;
              this.currencyForm.absenteeismQuitMinute =
                data.AbsenteeismQuitMinute;

              this.currencyForm.seriousLateMinuteCheck =
                this.currencyForm.seriousLateMinute != null;
              this.currencyForm.seriousQuitMinuteCheck =
                this.currencyForm.seriousQuitMinute != null;
              this.currencyForm.absenteeismMinuteCheck =
                this.currencyForm.absenteeismLateMinute != null ||
                this.currencyForm.absenteeismQuitMinute != null;

              this.currencyForm.timeInterval = data.FreeTimes.map((m) => {
                return {
                  start: m.StartTime,
                  end: m.EndTime,
                };
              });
              this.currencyForm.lateWork = data.CheckInDuration;
              this.currencyForm.leaveEarly = data.CheckOutDuration;
              if (data.IsSetCheckInElasticity) {
                this.currencyForm.lateWorkRadio = 1;
              } else if (data.IsSetCheckInHumanization) {
                this.currencyForm.lateWorkRadio = 2;
              }
              if (data.IsSetCheckOutElasticity) {
                this.currencyForm.leaveEarlyRadio = 1;
              } else if (data.IsSetCheckOutHumanization) {
                this.currencyForm.leaveEarlyRadio = 2;
              }
            } else {
              Object.keys(this.currencyForm).forEach((m) => {
                if (m == "timeZone") {
                  this.currencyForm[m] = "China Standard Time";
                } else if (m == "weekDay") {
                  this.currencyForm[m] = [];
                } else if (m == "timeInterval") {
                  this.currencyForm[m] = [
                    {
                      start: null,
                      end: null,
                    },
                  ];
                } else {
                  this.currencyForm[m] = null;
                }
              });
            }
          }
        })
        .finally(() => (this.loading = false));
    },
    /**
     * 特殊排班
     */
    getSpecialData() {
      this.loadingSpecial = true;
      this.$http
        .post("/Attendance/PlanBC/GetSpecialPlanBc.ashx", {
          UserId: this.generaId,
          teamId: this.teamValue,
          date: this.screenMonth.timeFormat("yyyy-MM-dd"),
        })
        .then((resp) => {
          if (resp.res == 0) {
            this.specialData = resp.data.Data;
          }
        })
        .finally(() => (this.loadingSpecial = false));
    },

    /**
     * 全选
     */
    handleSelAllWeek() {
      this.$D.LIST("week").forEach((e) => {
        this.currencyForm.weekDay.push(e.value);
      });
    },
    /**
     * 清除通用排班
     */
    handleClear() {
      this.clearBtnLoading = true;
      const data = { UserId: this.generaId, teamId: this.teamValue };
      this.$http
        .post("/Attendance/PlanBC/DelPlanBC.ashx", data)
        .then((resp) => {
          if (resp.res == 0) {
            this.$message({
              showClose: true,
              message: "清除成功",
              type: "success",
            });
          }
        })
        .finally(() => (this.clearBtnLoading = false));
    },
    /**
     * 通用排班保存
     */
    handleSave() {
      this.saveBtnLoading = true;
      const data = {
        PlanType: this.generaId || this.selMem.length ? 2 : 1,
        UserId:
          this.selMem && this.selMem.length
            ? this.selMem.map((m) => m.UsId)
            : this.generaId
            ? this.generaId
            : null,
        TimeZone: this.currencyForm.timeZone,
        PlanWeekWorkDay: this.currencyForm.weekDay,
        CheckInTime: this.currencyForm.startWork
          ? this.currencyForm.startWork.timeFormat("yyyy-MM-dd HH:mm:ss")
          : null,
        CheckOutTime: this.currencyForm.endWork
          ? this.currencyForm.endWork.timeFormat("yyyy-MM-dd HH:mm:ss")
          : null,
        CheckInDuration: this.currencyForm.lateWork,
        CheckOutDuration: this.currencyForm.leaveEarly,
        IsSetCheckInElasticity: !this.currencyForm.lateWorkRadio
          ? null
          : this.currencyForm.lateWorkRadio == 1
          ? true
          : false,
        IsSetCheckInHumanization: !this.currencyForm.lateWorkRadio
          ? null
          : this.currencyForm.lateWorkRadio == 2
          ? true
          : false,
        IsSetCheckOutElasticity: !this.currencyForm.leaveEarlyRadio
          ? null
          : this.currencyForm.leaveEarlyRadio == 1
          ? true
          : false,
        IsSetCheckOutHumanization: !this.currencyForm.leaveEarlyRadio
          ? null
          : this.currencyForm.leaveEarlyRadio == 2
          ? true
          : false,
        FreeTime: this.currencyForm.timeInterval.map((m) => {
          return {
            StartTime: m.start
              ? m.start.timeFormat("yyyy-MM-dd HH:mm:ss")
              : null,
            EndTime: m.end ? m.end.timeFormat("yyyy-MM-dd HH:mm:ss") : null,
          };
        }),
        teamId: this.teamValue,
        WageTypeId: this.currencyForm.hourlyWage,
        WageTypeOverId: this.currencyForm.hourlyWageOver,
        LastCheckOutTime: this.currencyForm.LastCheckOutTime
          ? this.currencyForm.LastCheckOutTime.timeFormat("yyyy-MM-dd HH:mm:ss")
          : null,
        IsMorrow: this.currencyForm.IsMorrow,
        SeriousLateMinute: this.currencyForm.seriousLateMinuteCheck
          ? this.currencyForm.seriousLateMinute
          : null,
        SeriousQuitMinute: this.currencyForm.seriousQuitMinuteCheck
          ? this.currencyForm.seriousQuitMinute
          : null,
        AbsenteeismLateMinute: this.currencyForm.absenteeismMinuteCheck
          ? this.currencyForm.absenteeismLateMinute
          : null,
        AbsenteeismQuitMinute: this.currencyForm.absenteeismMinuteCheck
          ? this.currencyForm.absenteeismQuitMinute
          : null,
      };
      this.$http
        .post("/Attendance/PlanBC/SavePlanBC.ashx", data)
        .then((resp) => {
          if (resp.res == 0) {
            this.$message({
              showClose: true,
              message: "设置成功",
              type: "success",
            });
          }
        })
        .finally(() => (this.saveBtnLoading = false));
    },
    /**
     * 设置某一天特殊的排班
     */
    setSpecial(day, obj) {
      this.selSpecialDay = day;
      this.selSpecialDayData = obj;
      this.$modal.show("special");
    },
    /**
     * 获取成员
     */
    getSelMember(val) {
      this.selMem = val;
    },
    /**
     * 清除时间区间
     */
    cancleInterval(ind) {
      this.currencyForm.timeInterval = this.currencyForm.timeInterval.filter(
        (m, index) => ind != index
      );
    },
    /**
     * 添加时间区间
     */
    addTimeInterval() {
      this.currencyForm.timeInterval.push({
        start: null,
        end: null,
      });
    },
  },
};
